<template>
<div>
  <div style="margin-bottom: 20px">
    <el-dropdown style="margin-right: 10px">
      <el-button class="my" type="danger">
        批量排班<i class="el-icon-arrow-down" style="margin-left: 8px"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-tooltip class="item" effect="dark" content="第一步:下载排班模板表" placement="left">
          <el-dropdown-item >
            <div @click="exportScheduling"><i class="el-icon-download"/>排班模板</div>
          </el-dropdown-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="第二步:导入排班表批量排班" placement="left">
          <el-dropdown-item>
            <div @click="uploadDialog = true"><i class="el-icon-upload2"/>导入排班</div>
            </el-dropdown-item>
        </el-tooltip>
      </el-dropdown-menu>
    </el-dropdown>

    <el-date-picker  :clearable="false"
        v-model="tableQuery.yearMonth"
        type="month" value-format="yyyy-MM"
        placeholder="选择月">
    </el-date-picker>
    <el-input v-model="tableQuery.coachName" style="width: 200px;margin: 0 10px" placeholder="请输入姓名"></el-input>
    <el-button :loading="tableLoading" type="danger" @click="selectSchedulingPage(0)">搜 索</el-button>
  </div>
  <el-table :data="coachAttendance" class="table" height="620px" border v-loading="tableLoading">
    <el-table-column label="姓名" width="90">
      <template #default="scope">
        <el-popover placement="bottom" width="200" trigger="click">
          <div style="text-align: center">
              <div style="text-align: left">姓名：{{scope.row.coachName}}</div>
            <div style="text-align: left;margin: 2px 0 10px 0">日期：{{tableQuery.year}}年{{tableQuery.month}}月</div>
            <div v-for="(classItem,classIndex) in attendanceGroup.classes" :key="classIndex">
              <el-popconfirm @confirm="updateScheduling(scope.row.coachId,null,classItem.id)"
                             :title="'确定要把'+scope.row.coachName+tableQuery.month+'月的全部考勤改为'+classItem.name+'吗'">
                <el-button class="class-name" style="width: 97%;" type="danger" slot="reference">{{ classItem.name }}</el-button>
              </el-popconfirm>
            </div>
            <el-popconfirm @confirm="updateScheduling(scope.row.coachId,null,0)"
                           :title="'确定要把'+scope.row.coachName+tableQuery.month+'月的全部考勤改为休息吗'">
              <el-button  class="class-name" style="width: 97%;" slot="reference" type="danger">休息</el-button>
            </el-popconfirm>
          </div>
          <template #reference>
            <span>{{scope.row.coachName}}</span>
          </template>
        </el-popover>

      </template>
    </el-table-column>
    <el-table-column align="center" v-for="(item, index) in titleList" :key="index" width="56">
      <template #header>
        <el-popover placement="bottom" width="200" trigger="click">
          <div style="text-align: center">
            <div style="text-align: left;margin: 2px 0 10px 0">日期：{{item.year}}年{{item.month}}月{{item.day}}日</div>
            <div v-for="(classItem,classIndex) in attendanceGroup.classes" :key="classIndex">
              <el-popconfirm @confirm="updateScheduling(null,item,classItem.id)"
                             :title="'确定要把'+item.year+'年'+item.month+'月'+item.day+'日'+'人员的全部考勤改为'+classItem.name+'吗'">
                <el-button class="class-name" style="width: 97%;" type="danger" slot="reference">{{ classItem.name }}</el-button>
              </el-popconfirm>
            </div>
            <el-popconfirm @confirm="updateScheduling(null,item,0)"
                           :title="'确定要把'+item.year+'年'+item.month+'月'+item.day+'日'+'人员的全部考勤改为休息吗'">
              <el-button  class="class-name" style="width: 97%;" slot="reference" type="danger">休息</el-button>
            </el-popconfirm>
          </div>
          <template #reference>
            <div style="font-weight: normal;color: #000000">
              <div style="font-weight: bold;">{{ item.day }}</div>
              <div style="color: #606266;font-size: 12px"
                   :style="(item.dayOfWeek == '六' || item.dayOfWeek == '日' ? 'color:red':'')">
                <span style="margin-right: 2px">周</span>{{ item.dayOfWeek }}
              </div>
            </div>
          </template>
        </el-popover>
      </template>
      <template #default="scope">
        <el-popover placement="bottom" width="200" trigger="click">
            <div style="text-align: center">
              <div style="text-align: left">姓名：{{scope.row.coachName}}</div>
              <div style="text-align: left;margin: 2px 0 10px 0">日期：{{item.year}}年{{item.month}}月{{item.day}}日</div>
              <div v-for="(classItem,classIndex) in attendanceGroup.classes" :key="classIndex">
                <el-popconfirm @confirm="updateScheduling(scope.row.coachId,item,classItem.id)"
                    :title="'确定把'+item.year+'年'+item.month+'月'+item.day+'日'+scope.row.coachName+'的考勤改为'+classItem.name+'吗'">
                  <el-button class="class-name" style="width: 97%;" type="danger" slot="reference">{{ classItem.name }}</el-button>
                </el-popconfirm>
              </div>
              <el-popconfirm @confirm="updateScheduling(scope.row.coachId,item,0)"
                             :title="'确定把'+item.year+'年'+item.month+'月'+item.day+'日'+scope.row.coachName+'的考勤改为休息吗'">
                <el-button  class="class-name" style="width: 97%;" slot="reference" type="danger">休息</el-button>
              </el-popconfirm>
            </div>
          <template #reference>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                <div v-if="scope.row.restList[index].type == 0">空</div>
                <div v-if="scope.row.restList[index].type == 1">休息</div>
                <div v-if="scope.row.restList[index].type == 2">
                  <div>{{scope.row.restList[index].classes.name}}<br/></div>
                  <div style="margin-top: 5px">{{scope.row.restList[index].classes.classDetailStr}}</div>
                </div>
              </div>
              <div v-if="scope.row.restList[index].type == 0" style="width:36px;height: 30px;"></div>
              <div v-if="scope.row.restList[index].type == 1" style="color: #C0C4CC">休</div>
              <div v-if="scope.row.restList[index].type == 2" >班</div>
            </el-tooltip>
          </template>
        </el-popover>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      style="text-align: right;margin: 20px 10px"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="10"
      @current-change="changePage"
      layout="total, prev, pager, next, jumper"
      :total="tableTotal">
  </el-pagination>
  <el-dialog
      title="批量排班"
      :visible.sync="uploadDialog"
      width="800px">
    <div v-loading="uploadLoading" style="text-align:center;height: 260px;">
      <div v-if="uploadDialog" style="width: 400px;margin: auto">
        <el-upload :auto-upload="false" action=" " drag :limit="1" :on-change="changeFile">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer" >
        <el-button @click="uploadDialog = false">取 消</el-button>
        <el-button type="primary" :loading="uploadLoading" @click="importScheduling()">{{uploadLoading ? '请稍等...':'导 入'}}</el-button>
      </div>
    </template>
  </el-dialog>
</div>
</template>

<script>
import {selectSchedulingPage,UpdateRestSchedule,exportScheduling,importScheduling} from "/src/api/attendance/scheduling";
import {Notification} from "element-ui";
export default {
  name: "SetScheduling",
  data() {
    return {
      attendanceGroup:null,
      tableQuery:{
        coachName:"",
        year:null,
        month:null,
        groupId:null,
        pageIndex:0,
        pageSize:10,
        yearMonth:null
      },
      uploadFileData: {
        file:null,
        year:null,
        month:null,
        attendanceGroupId:null,
      },
      uploadLoading:false,
      uploadDialog:false,
      tableTotal:0,
      titleList:[],
      coachAttendance: [],
      tableLoading:false,
    }
  },
  methods:{
    /**
     * 上传问价选中的文件信息
     */
    changeFile(file) {
      this.uploadFileData.file = file.raw;
    },
    /**
     * 导入排班
     */
    importScheduling(){
      this.uploadLoading = true;
      const date = this.tableQuery.yearMonth.split("-");
      const formData = new FormData();
      formData.append('file', this.uploadFileData.file);
      formData.append('year', date[0]);
      formData.append('month', date[1]);
      formData.append('attendanceGroupId', this.uploadFileData.attendanceGroupId);
      importScheduling(formData).then(res => {
        this.uploadLoading = false;
        if (res.status == 200 && res.data.message == "数据读取完毕") {
          this.uploadDialog = false;
          this.selectSchedulingPage();
          this.uploadFileData.file = null;
          this.$message({message: '导入成功！', type: 'success'});
        }else {
          Notification.error({ title: "提醒", message: res.data.message });
        }
      });
    },
    /**
     * 修改排班信息
     */
    updateScheduling(coachId,date,classesId){
      let groupId = this.attendanceGroup.id;
      let item = {classesId,coachId,groupId};
      if (date != null){
        let time = {year: date.year,month: date.month, date: date.day};
        item = {...item,...time}
      }else {
        let time = {year: this.tableQuery.year,month: this.tableQuery.month};
        item = {...item,...time}
      }
      UpdateRestSchedule(item).then(val=>{
        if (val.status == 200) {
          this.selectSchedulingPage();
          this.$message({message: '修改成功！', type: 'success'});
        }
      })
    },
    changePage(index){
      this.tableQuery.pageIndex = index;
    },
    selectSchedulingPage(){
      const date = this.tableQuery.yearMonth.split("-");
      this.tableQuery.year = date[0];
      this.tableQuery.month = date[1]
      this.tableLoading = true;
      selectSchedulingPage(this.tableQuery).then(val=>{
        this.tableLoading = false;
        if (val.status == 200){
          this.tableTotal = val.data.total;
          this.titleList = val.data.groupDateMap;
          this.coachAttendance = val.data.resultMap;
          this.attendanceGroup = val.data.attendanceGroup;
        }
      })
    },
    /**
     * 导出导入的文件模板
     */
    exportScheduling(){
      const date = this.tableQuery.yearMonth.split("-");
      this.tableQuery.year = date[0];
      this.tableQuery.month = date[1]
      exportScheduling(this.tableQuery).then(val=>{
        if (val.status == 200){
          let url = val.data;
          window.URL = window.URL || window.webkitURL;
          var name = url.substring(url.lastIndexOf("/") + 1, url.length);
          var xhr = new XMLHttpRequest();
          var a = document.createElement("a");
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function() {
            var file = new Blob([xhr.response], { type: "application/octet-stream" });
            a.href = window.URL.createObjectURL(file);
            a.download = name;
            a.click();
          };
          xhr.send();
        }
      })
    }
  },
  created() {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    this.tableQuery.yearMonth = year+'-'+month;
    const item = JSON.parse(this.$route.query.item);
    this.tableQuery.groupId = item.id;
    this.uploadFileData.attendanceGroupId = item.id;
    this.selectSchedulingPage();
  }
}
</script>

<style scoped>
.table{
  width: 100%;
  border-radius: 10px;
  padding: 6px 0px 10px 6px;
}
.class-name{
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  text-overflow: ellipsis; /* 用省略号表示被截断的文本 */
  margin: 4px 0;
  padding:12px 10px;
}
.my:hover{
  background-color: #f78989;
  border-radius: 8px;
  border: #f78989 1px solid;
  color: white;
}
</style>


