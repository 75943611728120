import request from '/src/api/request.js'

/**
 *  分页查询-排班表
 */
export function selectSchedulingPage(query) {
    return request({
        url: 'sys/RestSchedule/GetRestSchedulePage',
        method: 'POST',
        data: query,
    })
}


/**
 * 更新班次-排班表
 */
export function UpdateRestSchedule(item) {
    return request({
        url: 'sys/RestSchedule/UpdateRestSchedule',
        method: 'POST',
        data: item,
    })
}

/**
 *  导出-排班表
 */
export function exportScheduling(query) {
    return request({
        url: 'sys/RestSchedule/exportRestSchedule',
        method: 'POST',
        data: query,
    })
}

/**
 *  导入-排班表
 */
export function importScheduling(query) {
    return request({
        url: 'sys/RestSchedule/importRestSchedule',
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        },
        method: 'POST',
        data: query,
    })
}